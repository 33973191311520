<template>
  <div class="cbox">
    <div class="headbar">
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>企业管理</el-breadcrumb-item>
          <el-breadcrumb-item>企业通知编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="height: 85%;">

      <div style="max-width: 800px; margin: 0 auto;">
        <fieldset style="border:1px solid  #ccc; " v-if="activeName == 'second'">
          <legend>从公众号文章提取</legend>
          <div style>
            <div style="padding-bottom: 20px;">
              <el-input v-model="wxurl" style="width: 500px;" placeholder="请输入公众号文章地址"></el-input>
              <el-button style="margin-left: 10px;" @click="getWxArt">提取</el-button>
            </div>
          </div>
        </fieldset>

        <fieldset style="border:1px solid  #ccc; " v-if="activeName == 'third'">
          <legend>上传word文件</legend>
          <div style>
            <div style="padding-bottom: 20px;">
              <el-input readonly v-model="wxurl" style="width: 500px;" placeholder="请输入选择word文件"></el-input>
              <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
                :on-success="handleWordUpload" :show-file-list="false" name="image">
                <el-button style="margin-left: 10px;" icon="el-icon-upload">选择Word/Pdf文件</el-button>
              </el-upload>
            </div>
          </div>
        </fieldset>

        <fieldset style="border:1px solid  #ccc; background-color: #fff; ">
          <legend>编辑通知详情</legend>
          <el-form :model="EditItem" ref="EditItem" label-width="120px">
            <el-row style="padding-top: 20px;">
              <el-col :span="24">
                <el-form-item label="标题" required>
                  <el-input style="width: 90%;" v-model="EditItem.title" placeholder="请输入标题"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row style="padding-top: 20px;">
              <el-col :span="24">
                <el-form-item label="发送对象">
                  <el-button size="small" v-if="!sendToAll" style="margin-right: 20px;"
                    @click="showTarget">手动选择</el-button>
                  <el-checkbox v-model="sendToAll">全部企业</el-checkbox>
                </el-form-item>
              </el-col>
              <template v-if="!sendToAll">
                <el-col :span="12" v-for="item in multipleSelection" style="padding-left:45px;margin-bottom: 10px;">
                  <el-tag closable @close="handleRemoveSelect(item)">{{ item.firm_name }}</el-tag>
                </el-col>
              </template>
            </el-row>

            <el-row style="padding-top: 20px;">
              <el-col :span="24">
                <el-form-item label="提醒方式">
                  <el-checkbox v-model="sendEmailTemp">邮件推送</el-checkbox>
                  <el-checkbox v-model="sendSmsTemp" :disabled="true">短信推送</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding-top: 20px;">
              <el-col :span="24">
                <el-form-item label="内容">
                  <div style="line-height: initial; width: 100%; ">
                    <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="$store.state.pmid == 210" style="padding-top: 20px;">
              <el-col :span="24">
                <el-form-item label="附件">
                  <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
                             :on-success="uploadFiles" :show-file-list="false">
                    <el-button type="primary" size="small">上传文件</el-button>
                  </el-upload>
                  <ul v-if="EditItem.files && EditItem.files.length" class="el-upload-list el-upload-list--text" style="width: 320px;">
                    <li class="el-upload-list__item is-success" v-for="(file,index) in EditItem.files" :key="index">
                      <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{file.fileName}}</a>
                      <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-circle-check"></i></label>
                      <i class="el-icon-close" @click="handleRemoveFile(index)"></i></li></ul>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row>
            <el-col :span="22" style="text-align: right;">
              <el-button type="primary" icon="el-icon-s-promotion" @click="saveForm()">发布</el-button>
            </el-col>
          </el-row>
        </fieldset>
        <div style="height: 100px;"></div>
      </div>
    </div>
    <el-dialog class="cus_dialog" ref="ruleForm" title="选择企业" :visible.sync="dialogForm" destroy-on-close :close-on-click-modal="false"
      width="1200px">
      <div class="flexStretch" style="height: 100%;width:100%;">
        <div class="left" style="height: 100%;width:50%;">
          <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">

              <el-input placeholder="企业名称" v-model="searchForm.firm_name" size="small" class="input-with-select"
                style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>

              <el-select v-model="searchForm.info" clearable placeholder="企业性质" size="small"
                style="width:130px;margin: 0 10px 10px 0;">
                <el-option :label="item.param_desc" v-for="(item, idx) in qyxzArr" :key="idx"
                  :value="(idx)"></el-option>
              </el-select>
              <el-select v-model="searchForm.check_status" clearable placeholder="审核状态" size="small"
                         style="width:130px;margin: 0 10px 10px 0;">
                <el-option label="待审核" :value="0"></el-option>
                <el-option label="审核通过" :value="1"></el-option>
                <el-option label="审核不通过" :value="2"></el-option>
              </el-select>
              <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
                @click="() => { initEnterprise() }">搜索
              </el-button>
            </div>
          </el-row>
          <div style="width:560px;height:calc(100% - 52px);">
            <el-table ref="multipleTable" v-tableFit :data="enterList" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;" @selection-change="handleSelectionChange">
              <el-table-column type="selection" :reserve-selection="true" width="55" align="center" />
              <el-table-column type="index" label="序号" width="65" align="center" />
              <el-table-column label="企业名称" prop="firm_name" show-overflow-tooltip width="300" align="center" />
              <el-table-column label="企业性质" prop="info" width="130" align="center">
                <template slot-scope="scope">{{ qyxz[scope.row.info] || scope.row.enter_type || "-" }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="right" style="height: 100%;width:calc(50% - 20px);margin-left:20px;">
          <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%;height:52px;">
            已选企业列表（{{multipleSelection.length}}）
          </el-row>
          <div style="width:560px;height:calc(100% - 52px);">
            <el-table v-tableFit :data="multipleSelection" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
              <el-table-column type="index" label="序号" width="65" align="center" />
              <el-table-column label="企业名称" prop="firm_name" show-overflow-tooltip width="300" align="center" />
              <el-table-column label="企业性质" prop="info" width="130" align="center">
                <template slot-scope="scope">{{ qyxz[scope.row.info] || scope.row.enter_type || "-" }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- <el-transfer style="height:100%;" v-model="value" filterable :left-default-checked="leftDefaultChecked"
        :filter-method="filterMethod" filter-placeholder="请输入企业名称" :titles="titles" :button-texts="['左移', '右移']"
        :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }" @change="handleChange" :data="data">
        <span slot-scope="{ option }">{{ option.label }}</span>

      </el-transfer> -->

      <div slot="footer">
        <el-button class="transfer-footer" slot="right-footer" size="small" icon="el-icon-check" type="primary"
          @click="savefirmName()">确认选择</el-button>

      </div>
    </el-dialog>
    <div id="wxcontent" style="width: 100%; height: 0; overflow: hidden;"></div>
  </div>
</template>

<script>
import UEditor from "../../notify/ueditor.vue";
export default {
  components: {
    UEditor
  },
  data() {
    return {
      firmIdArr: [],
      titles: ["未选企业列表", "已选企业列表"],
      data: [],
      value: [],
      leftDefaultChecked: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      wxurl: "",
      dialogForm: false,
      editor: null,
      activeName: "first",
      sendToAll: false, //发送给所有用户
      EditItem: {
        title: "",
        id: 0,
        send_message: "",
        target_enters: "",
        send_channel: "",
        files: [],
      },
      sendSmsTemp: false,
      sendEmailTemp: false,
      searchForm: {
        firm_name: "",
        info: "",
      },
      qyxz: {}, qyxzArr:[],
      enterList: [],
      multipleSelection:[],
    };
  },
  mounted() {
    this.getComType().then(rr => {
     
    this.initEnterprise();
    });
    if (this.$route.query.id > 0) {
      this.EditItem.id = this.$route.query.id;
      setTimeout(() => {
        this.getInfo();
      },500)
    }
  },
  methods: {
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO'",
            used: 0
          })
          .then(res => {
            let qyxzArr = [];
 
            let qyxz = {};
           
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                qyxzArr.push(item);
                qyxz[item.param_value] = item.param_desc;
              }
              
            }
            this.qyxzArr = qyxzArr;
            
            this.qyxz = qyxz;
           
            resolve();
          });
      });
    },
    initEnterprise() {
      let data = {};
      data.firm_name = this.searchForm.firm_name;
      if (this.searchForm.info === 0 || this.searchForm.info === "0" || this.searchForm.info) {
        data.info = this.searchForm.info;
      }
      data.qyly = 1
      data.check_status = this.searchForm.check_status
      // console.log(220,data);
      this.$http.post("/api/enterprise_list_all", data).then(res => {
        let dataList = res.data;
        dataList.forEach((row, index) => {
          this.data.push({
            label: row.firm_name,
            key: row.id
          });
        });
        this.enterList = dataList
        
      });
    },
    getInfo() {
      this.$http
        .post("/api/zp_enter_note_info", {
          id: this.EditItem.id
        })
        .then(res => {
         
          this.EditItem = res.data;
          if (this.EditItem.files) {
            this.EditItem.files = JSON.parse(this.EditItem.files)
          }
          if (this.EditItem.send_channel == "email") {
            this.sendEmailTemp = true;
          }
          if (this.EditItem.send_channel == "sms") {
            this.sendSmsTemp = true;
          }
          if (this.EditItem.pick_type == "all") {
            this.sendToAll = true;
          } else {
            this.sendToAll = false;
            //默认选中的企业
            this.leftDefaultChecked = this.EditItem.pick_type.split(",");
            this.multipleSelection = this.enterList.filter(item => this.leftDefaultChecked.includes(item.id.toString()))
          }
          setTimeout(() => {
            this.editor.setContent(this.EditItem.send_message);
          }, 300);
        });
    },

    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection = val;
    },

    handleChange(value, direction, movedKeys) {
      //穿插框显示的数据
      this.firmIdArr = value;
    },
    savefirmName() {
      //穿插框确定
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message.warning('请选择企业！')
        return
      }
      // this.EditItem.pick_type = this.firmIdArr.toString();
      this.EditItem.pick_type = this.multipleSelection.map(n => { return n.id }).join(',');
      // console.log(296,this.EditItem.pick_type)
      this.dialogForm = false;
      
    },
    saveForm(status) {
      if (!this.EditItem.title) {
        this.$message({
          type: "error",
          message: "请输入标题"
        });
        return false;
      }

      if (this.sendToAll) {
        this.EditItem.pick_type = "all";
      } else {
        //发送企业对象
        if (this.sendToAll) {
          this.EditItem.pick_type = "all";
        }
      }
      this.EditItem.send_channel = "";
      if (this.sendEmailTemp) {
        this.EditItem.send_channel += "email";
      }
      if (this.sendSmsTemp) {
        this.EditItem.send_channel += "sms";
      }
      this.EditItem.send_message = this.editor.getContent();
      let item = JSON.parse(JSON.stringify(this.EditItem));
      if (item.files) {
        item.files = JSON.stringify(item.files)
      }

      this.$http.post("/api/zp_enter_note_edit", item).then(res => {
        this.$message({
          type: "success",
          message: "保存成功"
        });
        if (res.data.id) {
          this.EditItem.id = res.data.id;
        }
        this.$router.push("/zp_qytz");
      });
      return true;
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.send_message);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.send_message = editorInstance.getContent();
      });
      this.editor = editorInstance;
    },
    showTarget() {
      this.dialogForm = true;
      this.$nextTick(()=>{
        this.multipleSelection.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row,true)
        })
      })
    },
    handleWordUpload(e) {
      //上传word文件
      this.$http
        .post("/api/word2images", {
          url: e.src
        })
        .then(res => {
          let images = res.data;
          let html = "";
          for (let img of images) {
            html += '<img src="' + img + '" />';
          }
          this.editor.setContent(html);
        });
    },
    getWxArt() {
      //公众号提取
      this.$http
        .post("/api/getWxArticleContent", {
          url: this.wxurl
        })
        .then(res => {
          $("#wxcontent").html(res.data.html);
          $("img", "#wxcontent").each((idx, ele) => {
            $(ele).attr(
              "src",
              "//api-sch-saas.aiwx.org.cn/api/image?src=" +
              $(ele).attr("data-src")
            );
          });
          if (res.data.html) {
            this.$message.success("已读取文章内容");
          }
          setTimeout(() => {
            this.EditItem.send_message = $("#wxcontent").html();
            this.editor.setContent(this.EditItem.send_message);
          }, 1000);
        });
    },
    handleRemoveSelect(row) {
      //this.multipleSelection.forEach(() => {this.$refs.multipleTable.toggleRowSelection(row, false)})
      this.multipleSelection = this.multipleSelection.filter(item => item.id !== row.id)
    },
    uploadFiles(e) {
      this.EditItem.files.push(e)
    },
    handleRemoveFile(idx) {
      this.EditItem.files.splice(idx, 1)
    },
  }
};
</script>

<style scoped lang="less" type="text/css">
.fileitem {
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 6px;
}
</style>
<style type="text/css" scoped>
.el-tabs__nav-scroll {
  text-align: center;
}

.transfer-footer {
  margin-left: 20px;
  padding: 6px 5px;
}

.el-tabs__nav {
  display: inline-block;
  float: none;
}

/deep/ .el-transfer-panel {
  width: 400px;
  height: 100%;
 
}
/deep/ .el-transfer-panel__body {
   height: 100%;
 }

/deep/ .el-transfer-panel__list {
   height: 100%;
 }
</style>
